import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import _debounce from 'lodash/debounce'
import queryString from 'query-string'
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { directionSelector } from '@/core/services/App/store/AppSelector'
import {
  changeAppCurrency,
  changeDirectionsExchange,
  changeSymbolFiat,
} from '@/core/services/App/store/AppSlice'
import {
  ExchangeModel,
  ExchangeType,
  ICourseObg,
  ILimit,
  IPaymentMethod,
  IRequestCalculation,
} from '@/core/services/Exchange/ExchangeModel'
import {
  balancesSelector,
  coursesSelector,
  cryptocurrenciesSelector,
  fiatsSelector,
  limitsSelector,
  paymentsSelector,
  typesSelector,
} from '@/core/services/Exchange/store/ExchangeSelector'
import {
  calculationThunk,
  exchangeThunk,
  getCourseThunk,
} from '@/core/services/Exchange/store/ExchangeSlice'
import { setToast } from '@/core/services/Toast/store/ToastSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import useBackendError from '@/hooks/useBackendError'

import yup from '@/utils/yup-extended'

import { userSelector } from '@/modules/Auth/store/AuthSelector'

import { Button } from '../Button'
import { Dropdown } from '../Dropdown'
import { Icon } from '../Icon'
import { Loading } from '../Loading'
import { Select } from '../Select'
import { Title } from '../Title'
import './Exchange.scss'

const cnExchange = cn('Exchange')

interface IExchange
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isPage?: boolean
}

export const Exchange: FC<IExchange> = ({ className, isPage }) => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const [setBackendError] = useBackendError()
  const navigate = useNavigate()
  const courses = useAppSelector(coursesSelector)
  const cryptocurrencies = useAppSelector(cryptocurrenciesSelector)
  const fiats = useAppSelector(fiatsSelector)
  const balances = useAppSelector(balancesSelector)
  const limits = useAppSelector(limitsSelector)
  const payments = useAppSelector(paymentsSelector)
  const types = useAppSelector(typesSelector)
  const directionExchange = useAppSelector(directionSelector)
  const user = useAppSelector(userSelector)

  const [disabled, setDisabled] = useState<boolean>(true)
  const [focused, setFocused] = useState<string>('')
  const [action, setAction] = useState<ExchangeType>('buy')
  const [calculate, setCalculate] = useState<'from' | 'to' | null>(null)
  const [
    isCommisionVisible, //setIsCommisionVisible
  ] = useState(false)
  const [limit, setLimit] = useState<ILimit | null>(null)
  const [valid, setValid] = useState<{ input: string; message: string } | null>(
    null,
  )
  const [balance, setBalance] = useState<string>('')
  const [change, setChange] = useState<string>('')
  const [course, setCourse] = useState<string>('')
  const [direction, setDirection] = useState<string>('')
  const [fiat, setFiat] = useState<ICourseObg | null>(null)
  const [currency, setCurrency] = useState<ICourseObg | null>(null)
  const [fiatVal, setFiatVal] = useState<string[]>(['0', '00'])
  const [currencyVal, setCurrencyVal] = useState<string[]>(['0', '00000000'])
  const [currentMethods, setCurrentMethods] = useState<IPaymentMethod[]>([])
  const [currentMethod, setCurrentMethod] = useState<IPaymentMethod | null>(
    null,
  )

  useEffect(() => {
    if (directionExchange) {
      setAction(directionExchange.type.toLowerCase() as ExchangeType)

      const d = directionExchange.direction.split('/')
      const t = directionExchange.type

      const crypto = cryptocurrencies.find(
        el => el.label === (t === 'Sell' ? d[0] : d[1]),
      )

      if (crypto) setCurrency(crypto)
    }
  }, [directionExchange])

  useEffect(() => {
    if (focused === 'to') {
      setFocus('to', { shouldSelect: true })
    }
    if (focused === 'from') {
      setFocus('from', { shouldSelect: true })
    }
  }, [focused])

  useEffect(() => {
    if (balances) {
      const b = balances[direction]

      if (b) {
        setBalance(b)
      }
    }
  }, [balances, direction])

  useEffect(() => {
    if (limits) {
      const l = limits.find(el => el.currency === direction)

      if (l) {
        setLimit(l)
      }
    }
  }, [limits, direction])

  useEffect(() => {
    if (fiat && currency) {
      let str = ''

      if (action === 'buy') {
        str = `${fiat.label}/${currency.label}`
      } else {
        str = `${currency.label}/${fiat.label}`
      }

      setDirection(str)
      setValue('direction', str)

      dispatch(
        changeDirectionsExchange({
          type: action === 'buy' ? 'Buy' : 'Sell',
          direction: str,
        }),
      )

      dispatch(changeSymbolFiat(fiat.label === 'USD' ? '$' : '€'))
    }
  }, [fiat, currency, action])

  // console.log('courses', courses.find(el => el.method === action)?.data)
  // console.log('limit', limit)
  // console.log('limits', limits)
  // console.log('currentMethod', currentMethod)
  // console.log('action', action)
  // console.log('balance', balance)

  useEffect(() => {
    if (direction && payments.length) {
      const el = payments.find(el => el.direction === direction)

      if (el) {
        setCurrentMethods(el.methods)

        if (el.methods.length) {
          setCurrentMethod(el.methods[0])
          setValue('payment_id', el.methods[0].id)
        }
      } else {
        setCurrentMethods([])
        setCurrentMethod(null)
        setValue('payment_id', undefined)
      }
    }
  }, [direction, payments])

  useEffect(() => {
    const values = getValues()

    if (currentMethod && (values.from || values.to)) {
      calculation(values)

      if (change === 'to') {
        setCalculate('from')
        validationLimit('to', values.to)
      } else {
        setCalculate('to')
        validationLimit('from', values.from)
      }
    }

    if (currentMethod === null) {
      setValue('from', '')
      setValue('to', '')
      generatePreview('from', '0')
      generatePreview('to', '0')
      setCalculate(null)
    }
  }, [direction, currentMethod, change])

  useEffect(() => {
    if (direction && courses && action) {
      const course = courses.find(el => el.method === action)

      if (course) {
        const el = course.data.find(el => el.direction === direction)

        if (el && currentMethod) {
          const current = el.list.find(item => item.id === currentMethod.id)

          if (current) {
            if (action === 'buy') {
              const transformNumber = String(current.course).match(
                /^\d+[,|.]\d{5}/g,
              )

              if (transformNumber) {
                setCourse(`${transformNumber[0]}`)
              }
            } else {
              setCourse(current.course)
            }
          }
        } else {
          setCourse('')
        }
      }
    } else {
      setCourse('')
    }
  }, [direction, courses, action, currentMethod])

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    const regex = new RegExp(/\d|\./)
    const val = e.key

    if (val !== 'Backspace' && !regex.test(val)) e.preventDefault()
  }

  const schema = yup.object().shape({
    from: yup.string().trim().required(t('This field is required')),
    to: yup.string().trim().required(t('This field is required')),
    address: yup.string().trim().required(t('This field is required')),
  })

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    watch,
    setFocus,
  } = useForm<ExchangeModel>({
    defaultValues: new ExchangeModel(),
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const calculation = _debounce(async (values: ExchangeModel) => {
    if (
      ((!values.from || !values.to) && !values.direction) ||
      !values.payment_id
    )
      return

    const data: IRequestCalculation = {
      direction: values.direction as string,
      payment_id: +values.payment_id,
    }

    if (change === 'to' && values.to) {
      data.to = +values.to
    } else if (change === 'from' && values.from) {
      data.from = +values.from
    }

    try {
      const res = await dispatch(calculationThunk(data)).unwrap()

      if (res && res.to) {
        setValue('to', res.to)
        generatePreview('to', res.to)
      }

      if (res && res.from) {
        setValue('from', res.from)
        generatePreview('from', res.from)
      }

      setCalculate(null)

      if (user && !user.is_verify) {
        dispatch(
          setToast({
            type: 'error',
            message: t('To make an exchange, you need to pass verification'),
          }),
        )
      } else {
        setDisabled(false)
      }
    } catch (errors) {
      setBackendError(errors)
    }
  }, 1000)

  const exchange = async (form: ExchangeModel) => {
    if (!form.direction || !form.payment_id || !form.from) return

    try {
      await dispatch(
        exchangeThunk({
          sum: +form.from,
          direction: form.direction,
          payment_id: form.payment_id,
        }),
      ).unwrap()

      const query = queryString.stringify({
        from: form.from,
        address: form.address ? form.address : undefined,
        direction: form.direction,
        payment_id: form.payment_id,
        payment_method: currentMethod?.type.toLocaleLowerCase(),
        action,
      })

      navigate(`/profile/order?${query}`)
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const getCourse = async () => {
    try {
      await dispatch(getCourseThunk()).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  useEffect(() => {
    getCourse()
  }, [])

  useEffect(() => {
    if (fiat) {
      dispatch(changeAppCurrency(fiat.label))
    }
  }, [fiat])

  useEffect(() => {
    if (fiats.length && cryptocurrencies.length) {
      setFiat(fiats[0])
      setCurrency(cryptocurrencies[0])
    }
  }, [fiats, cryptocurrencies])

  const generatePreview = (name: string, value: string) => {
    if (name === 'from') {
      const arr = String(value).includes('.')
        ? String(value).split('.')
        : [`${value}`, '00']

      setFiatVal(arr.length === 2 ? arr : [...arr, '00'])
    }

    if (name === 'to') {
      const arr = String(value).includes('.')
        ? String(value).split('.')
        : [`${value}`, '00000000']

      setCurrencyVal(arr.length === 2 ? arr : [...arr, '00000000'])
    }
  }

  const validationLimit = (input: string, value: string | undefined) => {
    if (!limit || !value) return

    if (limit.min && +value < limit.min) {
      setValid({
        input,
        message: t('The entered value must be greater than', {
          value: `${limit.min} ${limit.label}`,
        }),
      })
    } else if (limit.min && +value > limit.max) {
      setValid({
        input,
        message: t('The entered value must be less than or equal to', {
          value: `${limit.max} ${limit.label}`,
        }),
      })
    } else {
      setValid(null)
    }
  }

  useEffect(() => {
    const subscription = watch((form, { type, name }) => {
      if (type === 'change') {
        if (name === 'from' && form.from) {
          setCalculate('to')
          generatePreview('from', form.from)
          calculation(form)
          validationLimit('from', form.from)
        }

        if (name === 'to' && form.to) {
          setCalculate('from')
          generatePreview('to', form.to)
          calculation(form)
          validationLimit('to', form.to)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, change])

  return (
    <div className={cnExchange(null, [className])}>
      {!isPage && (
        <div className={cnExchange('top')}>
          <Title>{t('Exchange')}</Title>
        </div>
      )}
      <div className={cnExchange('tabs')}>
        {types.map(el => (
          <button
            key={el.label}
            className={cnExchange('tab', {
              active: action === el.label.toLocaleLowerCase(),
            })}
            onClick={() =>
              setAction(el.label.toLocaleLowerCase() as ExchangeType)
            }
          >
            {t(el.label)}
          </button>
        ))}

        {currentMethod && (
          <Dropdown
            options={currentMethods}
            valueProp='id'
            labelProp='type'
            selectedProp='type'
            className={cnExchange('tab')}
            value={currentMethod}
            onClick={e => {
              setCurrentMethod(e)
              setValue('payment_id', e.id)
            }}
          />
        )}
      </div>
      <form className={cnExchange('form')} onSubmit={handleSubmit(exchange)}>
        <div className={cnExchange('el')}>
          <div className={cnExchange('head')}>
            {action === 'buy' ? (
              <div className={cnExchange('label')}>{t('You send')}</div>
            ) : (
              <div className={cnExchange('label')}>{t('You spend')}</div>
            )}

            <Select
              options={action === 'buy' ? fiats : cryptocurrencies}
              value={action === 'buy' ? fiat : currency}
              onChange={e => {
                action === 'buy' ? setFiat(e) : setCurrency(e)
              }}
              mb='none'
              className={cnExchange('select')}
            />
          </div>

          {calculate === 'from' ? (
            <Loading type='lines' className={cnExchange('value')} />
          ) : (
            <div className={cnExchange('wrap')}>
              {focused === 'from' ? (
                <div className={cnExchange('field')}>
                  <input
                    type='text'
                    {...register('from')}
                    className={cnExchange('input')}
                    onBlur={() => setFocused('')}
                    tabIndex={1}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              ) : (
                <div
                  className={cnExchange('value')}
                  onClick={() => {
                    setFocused('from')
                    setChange('from')
                  }}
                >
                  {fiatVal[0]} <small>.{fiatVal[1]}</small>
                </div>
              )}

              {((errors && errors.from && errors.from.message) ||
                (valid && valid.input === 'from')) && (
                <div className={cnExchange('error')}>
                  {valid && valid.input === 'from'
                    ? valid && valid.message
                    : errors?.from?.message}
                </div>
              )}
            </div>
          )}
        </div>
        {currency && fiat && course && (
          <div className={cnExchange('course')}>
            {action === 'buy' ? currency.label : fiat.label} = {course}
          </div>
        )}
        <div className={cnExchange('el')}>
          <div className={cnExchange('head')}>
            <div className={cnExchange('label')}>{t('You get')}</div>
            <Select
              options={action === 'buy' ? cryptocurrencies : fiats}
              value={action === 'buy' ? currency : fiat}
              onChange={e => {
                action === 'buy' ? setCurrency(e) : setFiat(e)
              }}
              mb='none'
              className={cnExchange('select')}
            />
          </div>

          {calculate === 'to' ? (
            <Loading type='lines' className={cnExchange('value')} />
          ) : (
            <div className={cnExchange('wrap')}>
              {focused === 'to' ? (
                <div className={cnExchange('field')}>
                  <input
                    type='text'
                    {...register('to')}
                    className={cnExchange('input')}
                    onBlur={() => setFocused('')}
                    tabIndex={1}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              ) : (
                <div
                  className={cnExchange('value')}
                  onClick={() => {
                    setFocused('to')
                    setChange('to')
                  }}
                >
                  {currencyVal[0]} <small>.{currencyVal[1]}</small>
                </div>
              )}

              {((errors && errors.to && errors.to.message) ||
                (valid && valid.input === 'to')) && (
                <div className={cnExchange('error')}>
                  {valid && valid.input === 'to'
                    ? valid && valid.message
                    : errors?.to?.message}
                </div>
              )}
            </div>
          )}

          <div className={cnExchange('available')}>
            {balance} {action === 'buy' ? currency?.label : fiat?.label}{' '}
            {t('available')}
          </div>
        </div>

        <div className={cnExchange('el')}>
          <div className={cnExchange('head')}>
            <div className={cnExchange('label')}>
              {t(
                action === 'buy'
                  ? 'For this wallet'
                  : 'I will send from this wallet',
              )}
            </div>
          </div>

          <div className={cnExchange('field')}>
            <input
              type='text'
              {...register('address')}
              className={cnExchange('input', { wallet: true })}
              placeholder={t('Enter wallet')}
            />

            {errors && errors.address && errors.address.message && (
              <div className={cnExchange('error')}>
                {errors.address.message}
              </div>
            )}
          </div>
        </div>

        <div className={cnExchange('action')}>
          {/* <Button
            type='submit'
            fluid
            size='lg'
            isLoading={isSubmitting}
            disabled={disabled || !currentMethod || !!(user && !user.is_verify)}
          >
            {t('Exchange')}
            <Icon name='exchange' className={cnExchange('icon')} />
          </Button> */}
        </div>
      </form>

      {isCommisionVisible && (
        <div className={cnExchange('commision')}>
          {t('Commissions are included ')}
        </div>
      )}

      {/* <div className={cnExchange('title')}>{t('Exchange')}</div>
      <div className={cnExchange('exchange')}>
        <div className={cnExchange('currency')}>
          {'BTC'}
          <Icon name='arrow-right' className={cnExchange('arrow')} />
          {'USD'}
        </div>
        <div className={cnExchange('currency')}>
          {'BTC'}
          <Icon name='arrow-right' className={cnExchange('arrow')} />
          {'USD'}
        </div>
      </div> */}
    </div>
  )
}
